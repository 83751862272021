import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../../components/Link';
import truncateWithDots from '../../../../helpers/truncateWithDots';
import { CategoryItem } from '../CategoryItem';
import propTypes from 'prop-types';


export const HomeImages = ({ categories }) => {
    const { t } = useTranslation();

    if (categories.length === 0) {
        return (<div><h3>{t('categories.empty')}</h3></div>);
    } else {
        return (categories.map((img) => (
            <div
                key={img._id}>
                <Link to={`/category/${img._id}`}>
                    <CategoryItem
                        name={truncateWithDots(img.name, 35)}
                        img_url={img.img_url}
                    />
                </Link>
            </div>
        )));
    }
}

HomeImages.propTypes = {
    categories: propTypes.array,
}