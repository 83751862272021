import React, { useState } from 'react';
import {
    ComponentInfo,
    ComponentName, ComponentRequiredWarning, ComponentSingle, DetailCardBody,
    DetailCardButton, DetailCardHeader
} from './style';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { AnimatePresence, motion } from "framer-motion"
import RadioButton from '../../../components/RadioButton';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useImages } from '../../../context/images';

export default function ProductDetailCard(props) {
    const [openCard, setOpenCard] = useState(false);
    const { t } = useTranslation();
    const { _id: id, name, opciones: options, required, type, onItemChecked, onItemSelected, selectedOptions } = props;
    const { contextImageGallery } = useImages();
    const [, setImages] = contextImageGallery;
    const multipleChoice = type === 'Multiple';

    return <div>
        <DetailCardHeader >
            <div>
                <ComponentName >
                    {name}
                </ComponentName>
                <ComponentInfo>
                    {required && <ComponentRequiredWarning >
                        {t('detail.required')}
                    </ComponentRequiredWarning>}
                    {!multipleChoice && <ComponentSingle >
                        {t('detail.selectOne')}
                    </ComponentSingle>}
                </ComponentInfo>
            </div>
            <DetailCardButton onClick={() => setOpenCard(!openCard)}>
                {!openCard
                    ? <IoIosArrowDown />
                    : <IoIosArrowUp />
                }
            </DetailCardButton>
        </DetailCardHeader>
        <AnimatePresence initial={false}>
            {openCard &&
                <motion.div
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.5 }}
                >
                    <DetailCardBody>
                        {options.map((option) => {
                            return option.active ?
                                <RadioButton
                                    key={option._id}
                                    type={multipleChoice ? 'checkbox' : 'radio'}
                                    value={!!selectedOptions?.find((opt) => opt.id === option._id)}
                                    onChange={() => {
                                        multipleChoice ? onItemChecked(id, name, required, option) : onItemSelected(id, name, required, option);
                                        setImages(option);
                                    }}
                                    label={option.name} price={option.salePrice}
                                /> : null;
                        })}
                    </DetailCardBody >
                </motion.div >}
        </AnimatePresence >
    </div >;
}

ProductDetailCard.propTypes = {
    name: propTypes.string,
    onItemSelected: propTypes.func,
    selectedOptions: propTypes.array,
    options: propTypes.array,
    id: propTypes.string,
}