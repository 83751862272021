import React, { useState } from 'react';
import { BsSearch, BsX } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../../components/Tabs';
import TabItem from '../../../../components/Tabs/TabItem';
import { CategoriesTabsComponent, SearchTabInput } from '../../style';
import { useDebounce } from 'react-use'
import { useSearchParams } from 'react-router-dom';

export default function CategoriesTabs({ categories, activeCategoryId, onCategoryChange, onSearch }) {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const q = searchParams.get('q')

    const [searchActive, setSearchActive] = useState(!!q)
    const [search, setSearch] = useState(q ?? '')

    useDebounce(
        () => {
            onSearch(search);
        },
        1000,
        [search]
    );

    return <CategoriesTabsComponent>
        <Tabs search={searchActive}>
            <TabItem fullWidth={searchActive}>
                <SearchTabInput name="search" placeholder={t('products.search')} active={searchActive} onChange={(evt) => setSearch(evt.target.value)} value={search} />
                {!searchActive ? <BsSearch width={24} onClick={() => setSearchActive(true)} /> : <BsX width={24} onClick={() => {
                    setSearchActive(false)
                    setSearch("")
                }} />}
            </TabItem>
            {categories.map(category => <TabItem key={category._id} active={activeCategoryId === category._id} onClick={() => onCategoryChange(category._id)}>
                {category.name}
            </TabItem>
            )}
        </Tabs>
    </CategoriesTabsComponent>;
}