import React from 'react';
import propTypes from 'prop-types';
import { ProductCardComponent, ProductCardContainer, ProductDescription, ProductInfo, ProductName, ProductPrice, ProductImage, ProductNotAvailable } from '../style';
import formatNumber from '../../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import truncateWithDots from '../../../helpers/truncateWithDots';

export const ProductCard = ({ active, name, descriptionProduct, imageUrl, salePrice }) => {
    const { t } = useTranslation();
    return (
        <ProductCardComponent>
            <ProductCardContainer>
                <ProductImage>
                    <img src={[imageUrl[0]]} alt={name} />
                </ProductImage>
                <ProductInfo>
                    <div>
                        <ProductName>
                            {name}
                        </ProductName>
                        <ProductDescription>
                            {truncateWithDots(descriptionProduct, 80)}
                        </ProductDescription>
                    </div>

                    {
                        !active ?
                            <ProductNotAvailable>{t('products.notAvilable')}</ProductNotAvailable>
                            :
                            <ProductPrice>
                                {formatNumber(salePrice)}
                            </ProductPrice>
                    }
                </ProductInfo>
            </ProductCardContainer>
        </ProductCardComponent>
    );
};
ProductCard.propTypes = {
    name: propTypes.string,
    descriptionProduct: propTypes.string,
    imageUrl: propTypes.array,
    salePrice: propTypes.number,
}
