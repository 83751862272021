import { lighten } from "polished";
import styled, { css } from "styled-components";

export const LayoutContainer = styled.div` 
    background-color: white;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    
    ::-webkit-scrollbar {
        display: none;
    }
`

export const Body = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    background-color: ${prop => prop.theme.colors.background};

    ::-webkit-scrollbar {
        display: none;
    }
`

export const AppFooter = styled.nav`
    display: flex;
    background-color: white;
    height: 60px;
    padding: 0px 20px;
    border-top: 1px solid black;    
`

export const FooterItem = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 26px;
    transition: color 0.5s;
    position: relative;
    
    ${({ selected }) => selected && css`
        svg {
            color: ${prop => prop.theme.colors.secondary};

        }
      `
    }

    &:hover {
        svg{
            color: ${prop => lighten(0.25, prop.theme.colors.secondary)};
        }
        cursor: pointer;
    }    

   
`