import propTypes from 'prop-types';
import React from 'react';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import { ArrowLeftButton, ArrowLeftIcon } from './style';

export const BackButton = ({ round, onClick }) => {

    return (
        <ArrowLeftButton
            onClick={onClick}
            round={round}
        >
            <ArrowLeftIcon src={ArrowLeft} alt="arrowLeft" />
        </ArrowLeftButton>
    )
}

BackButton.propTypes = {
    round: propTypes.bool,
    onClick: propTypes.func,
}

BackButton.defaultProps = {
    round: true
}  