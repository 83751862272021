import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { Navigate, Outlet, useParams } from "react-router";
import AddressDetail from '../pages/AddressDetail';
import Addresses from '../pages/Addresses';
import Orders from '../pages/Orders';
import Home from '../pages/Home';
import Cart from '../pages/Cart';
import PaymentInfo from '../pages/PaymentInfo';
import ProductDetail from '../pages/ProductDetail';
import PaymentOptions from '../pages/PaymentOptions';
import ProductsByCategory from '../pages/ProductsByCategory';
import Profile from '../pages/Profile';
import { useCart } from '../context/cart';
import { useShop } from '../context/shop';
import Main from '../layouts/Main';
import ProductEdit from '../pages/ProductEdit';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path=":storeId/:clientId" element={<Marketplace />}>
                <Route path="home" element={<Home />} />
                <Route path="category/:categoryId" element={<ProductsByCategory />} />
                <Route path="category/:categoryId/product/:productId" element={<ProductDetail />} />
                <Route path="edit-product/:productLineId" element={<ProductEdit />} />
                <Route path="cart" element={<Cart />} />
                <Route path="payment-info" element={<PaymentInfo />} />
                <Route path="orders" element={<Orders />} />
                <Route path="address-detail" element={<AddressDetail />} />
                <Route path="addresses" element={<Addresses />} />
                <Route path="payment-options" element={<PaymentOptions />} />
                <Route path="profile" element={<Profile />} />
                <Route
                    path=""
                    element={<Navigate to="home" />}
                />
                <Route
                    path="*"
                    element={<Navigate to="home" />}
                />
            </Route>
        </Routes >

    )
}

const Marketplace = () => {
    const { loadShop } = useShop();
    const { loadCart } = useCart()
    const { storeId, clientId } = useParams();

    useEffect(() => {
        loadShop(storeId)
    }, [loadShop, storeId]);

    useEffect(() => {
        loadCart(storeId, clientId)
    }, [loadCart, storeId, clientId])

    return <Main><Outlet /></Main>;
}
