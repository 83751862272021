import React from 'react';
import { HeaderSearch, HomeHeaderComponent, OverlayHeader } from '../style';
import { InputSearch } from '../../../components/InputSearch';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { StoreInfo } from '../StoreInfo';
import { StoreInfoModal } from '../StoreInfoModal';
import { useShop } from '../../../context/shop';

export const HomeHeader = ({ onSearch, searchValue, showInfo, setShowInfo }) => {
    const { t } = useTranslation();
    const { shop } = useShop();


    return <HomeHeaderComponent img={shop?.backgroundUrl}>
        <HeaderSearch>
            <InputSearch
                placeholder={t('categories.search')}
                value={searchValue}
                onChange={(event) => { onSearch(event.target.value) }} />
        </HeaderSearch>
        <StoreInfo store={shop} onClick={() => { setShowInfo(true) }} />
        {showInfo && <StoreInfoModal store={shop} show={showInfo} onClose={() => { setShowInfo(false) }} />}
        <OverlayHeader />
    </HomeHeaderComponent>;
}

HomeHeader.propTypes = {
    onSearch: propTypes.func,
    searchValue: propTypes.string,
    showInfo: propTypes.bool,
    setShowInfo: propTypes.func,
}

