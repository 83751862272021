import styled, { css } from "styled-components";

export const TabsComponent = styled.div` 
    background-color: white;
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
    /* flex-flow: wrap; */
    
    ${({ search }) => search && css`
        overflow-x: hidden;

        ${TabItemComponent} {
            min-width: 100%;
        }
    `}
        
    ::-webkit-scrollbar {
        display: none;
    }
`

export const TabItemComponent = styled.div` 
    display: inline-block;
    background-color: white;
    color: black;
    font-size: 16px;
    border: 1px solid ${prop => prop.theme.colors.grayLight};
    border-top: none;
    border-right: none;
    padding: 10px 20px;
    min-width: 0px;
    transition: all 0.5s;

    &:last-child {
        border-right: 1px solid;
        border-right-color: ${props => props.active ? prop => prop.theme.colors.secondary : prop => prop.theme.colors.grayLight};
    }

    &:hover{
        cursor: pointer;
    }

    ${({ active }) => active && css`
        border:  1px solid ${prop => prop.theme.colors.secondary};
        color: ${prop => prop.theme.colors.secondary};
      `
    }
`