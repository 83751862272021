import styled from "styled-components";
import { HeaderComponent } from "../../components/Header/style";

export const ProductDetailComponent = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
export const ProductDetailHeaderComponent = styled(HeaderComponent)` 
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    position: relative;
`
export const HeaderInfoContainer = styled.div` 
    display: flex;
    align-items: center;
    gap: 15px;
    height: 40px;
`
export const HeaderInfo = styled.div` 
    font-size: 25px;
    color: white;
    font-weight: bold;
    z-index: 2;
`
export const ProductDetailBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    padding: 10px 20px 0px;

    ::-webkit-scrollbar {
        display: none;
    }
`
export const ProductDetailContainer = styled.div` 
    background-color: ${prop => prop.theme.colors.background};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`
export const ProductDetailInfo = styled.div` 
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const ProductDetailCards = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
`
export const ProductDetailComments = styled.div` 
    margin-top: 20px;
`
export const ProductName = styled.div` 
    font-size: 25px;
    font-weight: bold;
`
export const ProductDescription = styled.div` 
    font-size: 16px;
    color: ${prop => prop.theme.colors.gray};
    margin-bottom: 15px;
    margin-top: 10px;
`
export const ProductDetailFooter = styled.div` 
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${prop => prop.theme.colors.grayLight};
    background-color: white;
`
export const OverlayHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    background: rgba(2, 30, 81, 0.4);
    z-index: 1;
`

export const EmptyDetailContainer = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 40px;
`
export const EmptyDetailIcon = styled.div`
    font-size: 100px;
    color: ${prop => prop.theme.colors.gray};
`
export const EmptyDetailText = styled.div`
    font-size: 18px;
    text-align: center;
    color: ${prop => prop.theme.colors.gray};
`