import propTypes from 'prop-types';
import React from 'react';
import { TabItemComponent } from '../style';

export default function TabItem(props) {
    return <TabItemComponent {...props}>
        {props.children}
    </TabItemComponent>;
}

TabItem.propTypes = {
    active: propTypes.bool,
}