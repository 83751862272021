import React, { useEffect, useState } from "react";
import { getCategories } from "../../services/getCategories";
import { HomeHeader } from './HomeHeader';
import { HomePage } from './HomePage';
import { HomeContainer } from './style';
import normalizeString from "../../helpers/normalizeString";
import { useParams } from 'react-router';
import { useCart } from '../../context/cart';
import { useTranslation } from "react-i18next";
import { EditAlert } from '../../../src/pages/ProductDetail/AddOrEditAlert/Edit';

export const Home = () => {
    const { cart } = useCart();
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const { storeId, clientId } = useParams();

    function filterCategories() {
        const result = categories.filter(category => (normalizeString(category.name).toLowerCase()).includes(normalizeString(searchValue).toLowerCase()));

        return result;
    }

    function searchCategory(value) {
        setSearchValue(value);
    }
    useEffect(() => {
        getCategories(storeId)
            .then(setCategories);
    }, [storeId])
    const filteredCategories = filterCategories();
    useEffect(() => {
        if (cart.id === null || cart.id === undefined || cart.statecart === 'Cancelado') {
            sessionStorage.removeItem('cart');
            setShow(clientId !== 'demo');
        } else {
            setShow(false);
        }
    }, [cart.id, cart.statecart, clientId])
    return(
        <HomeContainer>
           { show === false ?
            <>
                <HomeHeader onSearch={searchCategory} searchValue={searchValue} showInfo={showInfo} setShowInfo={setShowInfo} />
                <HomePage categories={searchValue.length > 0 ? filteredCategories : categories} showInfo={showInfo} />
            </>
            :
            <EditAlert show={show} message={t('detail.cartError')} />
            }
        </ HomeContainer>
        
    )
}

export default Home;
