import styled, { css } from "styled-components"
import { HeaderComponent } from "../../components/Header/style";
import { CardComponent } from "../../components/Card/style";

export const ProductsContainer = styled.div` 
    display: flex;
    flex-direction: column;
    height: 100%;  
    overflow: hidden;
`
export const ProductsHeaderComponent = styled(HeaderComponent)` 
    display: flex;
    justify-content: center;
    min-height: 100px;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
`
export const ProductsHeaderContainer = styled.div` 
    display: flex;
    align-items: center;
    gap: 30px;
`

export const AddressInfo = styled.div` 
    color: white;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
`

export const CategoriesTabsComponent = styled.div` 
    margin-bottom: 20px;
`
export const ProductsPageContainer = styled.div` 
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    height: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`

export const ProductCardComponent = styled(CardComponent)` 
    border: none;
    border-radius: 0%;
`
export const ProductCardContainer = styled.div` 
    display: flex;
    flex-direction: space-between;
    padding: 0px 25px;
    width: 100%;
`
export const ProductImage = styled.div`
    display: flex;
    justify-content: center;
    width: 145px;
    height: 135px;
    background-color: ${prop => prop.theme.colors.grayBackground};
`
export const ProductNotAvailable = styled.div`
    display: inline-block;
    font-size: 12px;
    text-align: center;
    background:  ${prop => prop.theme.colors.gray};;
    color: #fff;
    border-radius: 15px;
    padding: 2px 10px;
    font-weight: bold;
    margin: 5px 0px;
`

export const ProductInfo = styled.div` 
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
`
export const ProductName = styled.div` 
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 16px;
`
export const ProductDescription = styled.div` 
    font-size: 14px;
    color: ${prop => prop.theme.colors.gray};
`
export const ProductPrice = styled.div` 
    margin-top: 15px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
`
export const EmptyProductsContainer = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 40px;
`
export const EmptyProductsIcon = styled.div`
    font-size: 100px;
    color: ${prop => prop.theme.colors.gray};
`
export const EmptyProductsText = styled.div`
    font-size: 18px;
    text-align: center;
    color: ${prop => prop.theme.colors.gray};
`
export const OverlayHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    background: rgba(2, 30, 81, 0.4);
    z-index: 1;
`

export const SearchTabInput = styled.input`
    display: inline-block;
    width: 0px;
    border: none;
    appearance: none;
    transition: width 0.5s;

    &:focus {
        outline: none;
    }

    ${({ active }) => active && css`
        min-width: calc(90% - 24px);
    `}
    
`