import { ThemeProvider } from 'styled-components';
import './App.css';
import './styles/app.scss';
import { theme } from './theme';
import { AppRoutes } from './routes/index';
import CartProvider from './context/cart';
import ImageProvider from './context/images';
import ShopProvider from './context/shop';
import AddressProvider from './context/address';
import { BrowserRouter } from 'react-router-dom';
import useWindowSize from './hooks/useWindowSize';
import InvalidDevice from './pages/InvalidDevice';

function App() {
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  window.addEventListener('load', setVh);
  window.addEventListener('resize', setVh);
  const size = useWindowSize();

  return (
    <>
      {size.width <= 600 ?
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ShopProvider>
              <ImageProvider>
              <AddressProvider>
                <CartProvider>
                  <AppRoutes />
                </CartProvider>
              </AddressProvider>
              </ImageProvider>
            </ShopProvider>
          </BrowserRouter>
        </ThemeProvider> :
        <ThemeProvider theme={theme}>
          <InvalidDevice />
        </ThemeProvider>
      }
    </>
  );
}

export default App;