import React, { useState } from "react";
import Button from "../../../components/Button";
import ProductCounter from "../ProductCounter";
import ProductDetailCard from "../ProductDetailCard";
import {
  ProductDescription,
  ProductDetailBody,
  ProductDetailCards,
  ProductDetailComments,
  ProductDetailContainer,
  ProductDetailFooter,
  ProductName,
} from "../style";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer";
import NoProductFooter from "../NoProductFooter";
import { ToastContainer } from "react-toastify";
import Textarea from "../../../components/Textarea";
import propTypes from "prop-types";

export const ProductDetailPage = (props) => {
  const { t } = useTranslation();
  const {
    active,
    name,
    description,
    optionsGroup,
    enableButton,
    buttonTitle,
    onClick,
    initialState,
  } = props;
  const requiredOptionsGroup = optionsGroup.filter((group) => group.required);
  const [quantity, setQuantity] = useState(initialState?.initQuantity || 1);
  const [comments, setComments] = useState(initialState?.initComments || "");
  const [groups, setGroups] = useState(
    initialState?.selectedOptions ||
      requiredOptionsGroup?.map((optionsGroup) => {
        return {
          id: optionsGroup._id,
          name: optionsGroup.name,
          required: optionsGroup.required,
          options: [],
        };
      })
  );

  const selectOption = (groupId, groupName, isRequired, selectedOption) => {
    setGroups((prevOptions) => {
      const result = [...prevOptions];

      const index = result.findIndex((group) => group.id === groupId);

      if (index === -1) {
        result[result.length] = {
          id: groupId,
          name: groupName,
          required: isRequired,
          options: [
            {
              id: selectedOption._id,
              name: selectedOption.name,
              salePrice: selectedOption.salePrice,
            },
          ],
        };
      } else {
        if (selectedOption._id === result[index].options[0]?.id && !result[index].required) {
          result.splice(index, 1);
        } else {
          result[index] = {
            ...result[index],
            options: [
              {
                id: selectedOption._id,
                name: selectedOption.name,
                required: selectedOption.required,
                salePrice: selectedOption.salePrice,
              },
            ],
          };
        }
      }

      return result;
    });
  };
  const toggleOption = (groupId, groupName, isRequired, selectedOption) => {
    setGroups((prevOptions) => {
      const result = [...prevOptions];

      const index = result.findIndex((group) => group.id === groupId);

      if (index === -1) {
        result[result.length] = {
          id: groupId,
          name: groupName,
          required: isRequired,
          options: [
            {
              id: selectedOption._id,
              name: selectedOption.name,
              salePrice: selectedOption.salePrice,
            },
          ],
        };
      } else {
        const optionIndex = result[index].options.findIndex(
          (option) => option.id === selectedOption._id
        );

        if (optionIndex === -1) {
          result[index] = {
            ...result[index],
            options: [
              ...result[index].options,
              {
                id: selectedOption._id,
                name: selectedOption.name,
                required: selectedOption.required,
                salePrice: selectedOption.salePrice,
              },
            ],
          };
        } else {
          result[index] = {
            ...result[index],
            options: result[index].options.filter(
              (option) => option.id !== selectedOption._id
            ),
          };

          if (result[index].options.length === 0 && !result[index].required) {
            result.splice(index, 1);
          }
        }
      }

      return result;
    });
  };

  const isDisabled = () => {
    // checks if there are required groups and if those groups have options
    const requiredGroups = groups.filter((group) => group.required);
    const requiredOptions = groups.filter(
      (group) => group.required && group.options.length > 0
    );

    return (
      requiredGroups.length > 0 &&
      requiredOptions.length !== requiredGroups.length
    ) ? true : false;
  };

  return (
    <ProductDetailContainer>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        type="info"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ProductDetailBody>
        <div>
          <ProductName>{name}</ProductName>
          <ProductDescription>{description}</ProductDescription>
        </div>

        <ProductDetailCards>
          {optionsGroup?.map((optionGroup) => {
            return (
              <ProductDetailCard
                key={optionGroup._id}
                required={optionGroup.required}
                type={optionGroup.type}
                selectedOptions={
                  groups?.find((group) => group.id === optionGroup._id)?.options
                }
                onItemChecked={toggleOption}
                onItemSelected={selectOption}
                {...optionGroup}
              />
            );
          })}
          <ProductDetailComments>
            <Textarea
              label={t("detail.comments")}
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </ProductDetailComments>
        </ProductDetailCards>
      </ProductDetailBody>
      {active ? (
        <React.Fragment>
          <ProductDetailFooter>
            <ProductCounter
              value={quantity}
              onAdd={() => {
                setQuantity(quantity + 1);
              }}
              onDelete={() => {
                setQuantity(quantity - 1);
              }}
            />
          </ProductDetailFooter>

          <Footer>
            <Button
              title={buttonTitle}
              disabled={quantity === 0 || !enableButton || isDisabled()}
              onClick={() => onClick(groups, comments, quantity)}
            />
          </Footer>
        </React.Fragment>
      ) : (
        <NoProductFooter />
      )}
    </ProductDetailContainer>
  );
};

ProductDetailPage.propTypes = {
  name: propTypes.string,
  description: propTypes.string,
  optionsGroup: propTypes.array,
  enableButton: propTypes.bool,
  buttonTitle: propTypes.string,
  onClick: propTypes.func,
  initialState: propTypes.object,
};
