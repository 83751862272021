import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getProduct } from '../../services/products/getProduct';
import { useCart } from '../../context/cart';
import ProductDetailHeader from '../ProductDetail/ProductDetailHeader';
import { ProductDetailPage } from '../ProductDetail/ProductDetailPage';
import { ProductDetailComponent } from '../ProductDetail/style';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from '../../hooks/useRouteNavigation';
import { EditAlert } from '../ProductDetail/AddOrEditAlert/Edit';


export default function ProductEdit() {
    const { productLineId } = useParams();
    const { cart, editProduct } = useCart();
    const { t } = useTranslation();
    const [product, setProduct] = useState({});
    const [enableButton, setEnableButton] = useState(true);
    const goToRoute = useRouteNavigation();
    const [show, setShow] = useState(false);

    const findProductByLineId = (productLineId) => {
        const product = cart.products.find((product) => product.lineId === productLineId);

        return product;
    }

    const productId = findProductByLineId(productLineId)?.id;

    useEffect(() => {
        getProduct(productId)
            .then(setProduct);
    }, [productId]);


    const notifyProductEdited = (name) => toast.success(t('detail.productEdited', { product: name }));

    const editCartProduct = (groups, comments, quantity) => {
        const editedProduct = findProductByLineId(productLineId);
        editProduct({
            lineId: editedProduct?.lineId,
            id: editedProduct?.id,
            name: editedProduct?.name,
            description: editedProduct?.description,
            price: editedProduct?.price,
            purchase: editedProduct?.purchase,
            quantity: quantity,
            optionsGroup: groups,
            comments: comments,
        }).then(() => {
            setEnableButton(false);
            goToRoute('/home');
            goToRoute('/cart');
    
            setTimeout(function () {
                notifyProductEdited(editedProduct?.name);
            }, 250);

        }).catch (error => {
            setShow(true);
        })
    }

    return <ProductDetailComponent>
        { show === false ?
        <>
        <ProductDetailHeader productName={product.name} productImg={product.imageUrl} />
        {Object.keys(product).length > 0 &&
            <ProductDetailPage
                name={product.name}
                description={product.descriptionProduct}
                active={product.active}
                optionsGroup={product.optionsGroup}
                enableButton={enableButton}
                buttonTitle={t('detail.editProduct')}
                onClick={(groups, comments, quantity) => editCartProduct(groups, comments, quantity)}
                initialState={
                    {
                        selectedOptions: findProductByLineId(productLineId)?.optionsGroup,
                        initComments: findProductByLineId(productLineId)?.comments,
                        initQuantity: findProductByLineId(productLineId)?.quantity,
                    }
                }
            />}
        </>
        :
        <EditAlert show={show} message={t('detail.productAddedError')} />
        }
    </ProductDetailComponent>;
}
