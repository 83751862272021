import styled, { css } from "styled-components";
import { lighten } from 'polished'

export const CounterContainer = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    margin-top: 5px;
`
export const CounterButton = styled.button` 
    outline: none;
    border: none;
    background: ${prop => prop.theme.colors.grayBackground};
    vertical-align: middle;
    padding: 8px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 22px;
    display: flex;
    transition: all ease 0.5s;

    // Disabled button
    ${({ counterValue }) =>
        counterValue === 0 &&
        css`
      background: ${prop => lighten(0.15, prop.theme.colors.grayMedium)};
      color: ${prop => prop.theme.colors.grayLight};
      pointer-events: none;
      `
    }
`

export const CounterValue = styled.div`
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 0px 20px;
`