import React, { useState, useEffect } from 'react';
import { ProductCard } from '../ProductCard';
import { ProductsPageContainer } from '../style';
import { getProductsByCategories } from '../../../services/products/getProductsByCategories';
import { useParams } from 'react-router';
import Link from '../../../components/Link';
import EmptyProducts from '../EmptyProducts';
import CategoriesTabs from './CategoriesTabs';
import { getCategories } from '../../../services/getCategories';
import { searchProduct } from '../../../services/products/searchProduct';
import { useSearchParams } from 'react-router-dom';
import { useRouteNavigation } from '../../../hooks/useRouteNavigation';

export const ProductsPage = () => {
    const { storeId, categoryId } = useParams();
    const goToRoute = useRouteNavigation();

    const [searchParams, setSearchParams] = useSearchParams();
    const q = searchParams.get('q')

    const [products, setProducts] = useState([]);
    const [searchProducts, setSearchProducts] = useState(null);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories(storeId).then(setCategories)
    }, [storeId])

    useEffect(() => {
        getProductsByCategories(categoryId)
            .then(setProducts);
    }, [categoryId])

    useEffect(() => {
        if (q) {
            searchProduct(q, storeId).then(setSearchProducts)
        }
        else {
            setSearchProducts(null)
        }
    }, [q, storeId])

    const listProducts = searchProducts === null ? products : searchProducts

    return (
        <ProductsPageContainer>
            <CategoriesTabs
                categories={categories}
                activeCategoryId={categoryId}
                onCategoryChange={(categoryId) => goToRoute(`/category/${categoryId}`, { replace: true })}
                onSearch={(search) => setSearchParams({ q: search }, { replace: true })}
            />
            {
                listProducts.length > 0

                    ? listProducts.map(product => (
                        <Link to={`/category/${categoryId}/product/${product._id}`}
                            key={product._id}
                        >
                            <ProductCard
                                {...product}
                            />
                        </Link>
                    ))
                    : <EmptyProducts />
            }
        </ProductsPageContainer>
    );
}