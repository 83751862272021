import React from 'react';
import { ProductsHeader } from './ProductsHeader';
import { ProductsPage } from './ProductsPage';
import { ProductsContainer } from './style';

export default function ProductsList() {
    return <ProductsContainer>
        <ProductsHeader />
        <ProductsPage />
    </ProductsContainer>;
}
