import { useTranslation } from "react-i18next";
import { BsBasket } from "react-icons/bs";
import { EmptyProductsContainer, EmptyProductsIcon, EmptyProductsText } from '../style';

export default function EmptyProducts() {
    const { t } = useTranslation();

    return <EmptyProductsContainer>
        <EmptyProductsIcon>
            <BsBasket />
        </EmptyProductsIcon>
        <EmptyProductsText>
            {t('products.empty')}
        </EmptyProductsText>
    </EmptyProductsContainer >

}