import React from 'react';
import { BackButton } from '../../../components/BackButton';
import { useGoBack } from '../../../hooks/useGoBack';
import { HeaderInfo, HeaderInfoContainer, OverlayHeader, ProductDetailHeaderComponent } from '../style';
import propTypes from 'prop-types';

export default function ProductDetailHeader({ productName, productImg }) {
    const goBack = useGoBack();

    return <ProductDetailHeaderComponent img={productImg}>
        <HeaderInfoContainer>
            <BackButton onClick={goBack} />
            <HeaderInfo>
                {productName}
            </HeaderInfo>
        </HeaderInfoContainer>
        <OverlayHeader />
    </ProductDetailHeaderComponent>;
}

ProductDetailHeader.propTypes = {
    productName: propTypes.string,
    productImg: propTypes.string,
}