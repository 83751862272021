import propTypes from 'prop-types';
import React from 'react';
import ImageCard from '../../../../components/ImageCard';
import useWindowSize from '../../../../hooks/useWindowSize';
import { CategoryItemCard, CategoryItemTitle, Overlay } from '../../style';

export const CategoryItem = ({ name, img_url }) => {
    const screenSize = useWindowSize();

    return (
        <CategoryItemCard>
            <ImageCard img={img_url} alt={name} width={screenSize.width}>
                <CategoryItemTitle>
                    {name}
                </CategoryItemTitle>
                <Overlay />
            </ImageCard>
        </CategoryItemCard>
    )
};

CategoryItem.propTypes = {
    img_url: propTypes.string,
    name: propTypes.string,
}

