import React from 'react';
import { BackButton } from '../../../components/BackButton';
import { useGoBack } from '../../../hooks/useGoBack';
import { useShop } from '../../../context/shop';
import { AddressInfo, ProductsHeaderComponent, ProductsHeaderContainer, OverlayHeader } from '../style';

export const ProductsHeader = () => {
    const goBack = useGoBack();
    const { shop } = useShop();

    return (
        <ProductsHeaderComponent img={shop?.backgroundUrl}>
            <ProductsHeaderContainer>
                <BackButton onClick={goBack} />
                <AddressInfo>
                    {`${shop.name} - ${shop.address}`}
                </AddressInfo>
            </ProductsHeaderContainer>
            <OverlayHeader />
        </ProductsHeaderComponent>
    )
}
